@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap');

body{
    font-family: 'Inter', sans-serif;
    scroll-behavior: smooth;
}

header{
    z-index: 10001 !important;
}

